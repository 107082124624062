


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import CoachApi from '@/api/coach'

@Component({})
export default class PtApplyPrice extends Vue {
  @Prop({default: () => false}) private show!: boolean
  @Prop({default: () => ''}) private courseId!: string

  private api = new CoachApi()
  private currentShow = this.show
  private currentCourseId = ''
  private priceValue = ''
  private pShow = false

  @Watch('courseId', {immediate: true})
  public getCourseId(vl: string, ov: string) {
    if (vl !== ov) {
      this.currentCourseId = vl
    }
  }

  private async handleConfirm() {
    if (!this.priceValue) {
      this.$toast('请输入价格')
      return false
    }

    if (this.currentCourseId) {
      await this.api.applyCourseAsync(this.currentCourseId, this.priceValue)
      this.$toast('申请成功，等待审核', {type: 'success'})
      this.handleClose()
    }
  }

  private handleBeforeClose(action: any, done: any) {
    done(false)
    if (action !== 'confirm') {
      this.handleClose()
    }
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
