













































import {Component, Vue} from 'vue-property-decorator'
import CoachApi from '@/api/coach'
import PtApplyPrice from './components/PtApplyPrice.vue'

@Component({
  components: {
    PtApplyPrice
  }
})
export default class PtApply extends Vue {
  private api = new CoachApi()
  private priceShow = false
  private courseId = ''

  private courses = new Array()

  private async activated() {
    await this.getList()
  }

  private async getList() {
    this.courses = await this.api.getCoursesByApplyAsync()
  }

  private handlePriceShow(item: any) {
    this.priceShow = true
    this.courseId = item.id
  }

  private async handleClose() {
    this.priceShow = false
    await this.getList()
  }
}
